<template>
  <div>
    <h2 class="display-5">{{ $t("users.users") }}</h2>
    <b-list-group class="mb-4">
      <b-list-group-item class="btn-light" :to="{name: 'Users'}" exact-active-class="active">
        <BIconPeopleFill/> {{ $t('users.all-users') }}
      </b-list-group-item>
      <hr/>
      <b-list-group-item class="btn-light" :to="{name: 'AddUser'}" exact-active-class="active">
        <BIconPersonPlusFill/> {{ $t('users.add-user') }}
      </b-list-group-item>
      <b-list-group-item class="btn-light" :to="{name: 'DisabledUsers'}" exact-active-class="active">
        <BIconPersonXFill/> {{ $t('users.disabled-users') }}
      </b-list-group-item>
    </b-list-group>

    <h2 class="display-5">{{ $t('users.roles') }}</h2>
    <b-list-group class="mb-4">
      <b-list-group-item class="btn-light" :to="{name: 'Roles'}" exact-active-class="active">
        <BIconClipboard/> {{ $t('users.all-roles') }}
      </b-list-group-item>
      <hr>
      <b-list-group-item class="btn-light" :to="{name: 'AddUserRole'}" exact-active-class="active">
          <BIconClipboardPlus/> {{ $t('users.add-role') }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import {BIconPersonPlusFill, BIconPeopleFill, BIconPersonXFill, BIconClipboard, BIconClipboardPlus} from "bootstrap-vue";
export default {
  name: "UserMenu",
  components: { BIconPersonPlusFill, BIconPeopleFill, BIconPersonXFill, BIconClipboard, BIconClipboardPlus}
}
</script>
<style></style>
