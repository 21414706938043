<template>
  <div class="p-1">
    <b-form @submit="onSubmit" autocomplete="off">
      <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('users.role-name')" label-for="name">
        <b-form-input id="name" v-model="role.name" @blur="checkName" :placeholder="$t('users.enter-role-name')"></b-form-input>
      </b-form-group>
      <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('users.role-description')" label-for="desc">
        <b-form-textarea id="desc" v-model="role.description" :placeholder="$t('users.enter-role-description')" cols="4"></b-form-textarea>
      </b-form-group>
      <b-form-group label-cols-sm="12" label-cols-md="4">
        <b-button type="submit" variant="primary">{{ $t('users.save-role')}}</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { BForm, BFormTextarea, BFormFile, BImg, BFormCheckbox } from 'bootstrap-vue'

export default {
  name : "RoleForm",
  components : {BForm, BFormTextarea },
  props: {
    role: Object
  },
  data : function() {
    return {
    }
  },
  computed: {},
  methods : {
    checkName: function(e) {
      // console.log(this.role.name);
    },

    onSubmit: function(event) {
      event.preventDefault();
      // console.log(this.role);
      this.$http.post("/cms/api/users/roles/save", this.role, {headers: {Authorization: this.getAuthData().accessToken}})
        .then(function(r) {
					if (r.data === "OK") {
						// console.log(r);
					}
      })

    }
  }
}
</script>
<style></style>
