<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
            <h1 class="text-center">{{ $t('users.roles') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" md="3">
          <UserMenu />
        </b-col>
        <b-col cols="12" md="9" pl="3">
          <b-overlay :show="showOverlay">
            <RoleForm :role="role" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import UserMenu from "@/components/admin/user/UserMenu.vue";
import RoleForm from "@/components/admin/user/roles/RoleForm.vue";

export default {
  name : "ListUsers",
  components: { NavBar, UserMenu, RoleForm },
  data: function() {
    return {
      role: {},
      showOverlay: true
    }
  },
  created: function() {
    this.$http.get('/cms/api/users/roles/edit/' + this.$route.params.roleId + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.role = r.data;
    }).catch(e => { this.handleError(e); });
    this.showOverlay = false
  }
}
</script>

<style></style>
